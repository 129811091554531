import { Injectable } from '@angular/core';

const LOCAL_STORAGE_ORIGIN_KEY = 'SELL_CONNECT_COOKIE_POLICY_ACCEPTED';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor() {}

  public storeItem(item: string): void {
    localStorage?.setItem(LOCAL_STORAGE_ORIGIN_KEY, item);
  }

  public getStoredItem(): string | null {
    return localStorage?.getItem(LOCAL_STORAGE_ORIGIN_KEY);
  }

  public removeStoredItem(): void {
    localStorage?.removeItem(LOCAL_STORAGE_ORIGIN_KEY);
  }
}
