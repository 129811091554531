export interface AlertModel {
  type: AlertType;
  message?: string;
  timer?: number;
  state?: Flow;
  data?: any;
  dismissible?: boolean;
}

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'danger',
  WARNING = 'warning',
  INFO = 'info'
}

export enum Flow {
  OTP,
  FIRST_VISIT,
  MESSAGE,
  OTT
}
