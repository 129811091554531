<div class="modal-header pb-0">
  <h1 class="modal-title" i18n="@@newlogin.policy.title">Legal notices</h1>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div>
  <ul class="nav justify-content-end">
    <li class="nav-item">
      <a href="javascript:void(0);" class="nav-link" (click)="scrollTo('privacyPolicy')" i18n="@@newlogin.policy.achor"
        >Privacy Policy</a
      >
    </li>
    <li class="nav-item">
      <a href="javascript:void(0);" class="nav-link" (click)="scrollTo('cookiesPolicy')" i18n="@@newlogin.cookie.achor"
        >Cookie Policy</a
      >
    </li>
  </ul>
</div>
<div class="modal-body" [innerHTML]="content"></div>
