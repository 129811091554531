import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClpLoginResponse } from '@seco/login';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  defaultEventCode = 999999;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject('Window') private readonly window: Window
  ) {}

  redirect(path: string) {
    this.window.open(path);
  }

  createForm(bfUrl: string, clpLoginResponse: ClpLoginResponse, isSsoFlow = false) {
    const form: HTMLFormElement = this.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', bfUrl);
    const addInputToForm = (redirectionForm: HTMLFormElement, name: string, value: string) => {
      const input: HTMLInputElement = this.document.createElement('input');
      input.setAttribute('type', 'text');
      input.setAttribute('name', name);
      input.setAttribute('value', value);
      redirectionForm.appendChild(input);
    };
    addInputToForm(form, 'ACCESS_TOKEN', clpLoginResponse.accessToken);
    addInputToForm(form, 'ID_TOKEN', clpLoginResponse.idToken);
    if (isSsoFlow) {
      addInputToForm(form, 'IS_EXT_SSO', 'TRUE');
    } else {
      addInputToForm(form, 'NONCE', clpLoginResponse.nonce);
    }
    addInputToForm(form, 'ACTION', 'UMSignInByAccessToken');
    this.document.getElementsByTagName('body')[0].appendChild(form);
    this.submitForm(form);
  }

  /**
   * Decode function of eventCode to pass to getEventCodeMessageAction
   */
  decodeString(eventCode: string): number {
    let trueCode = Number(eventCode.substring(0, eventCode.length - 1));
    const validationCode: string = eventCode.substring(eventCode.length - 1, eventCode.length);
    let obfError = 0;
    let errorCode = Number.parseInt(trueCode.toString(), 8);
    if (Number.isNaN(errorCode)) {
      return this.defaultEventCode;
    }
    trueCode = errorCode;
    while (errorCode !== 0) {
      obfError += errorCode % 10;
      errorCode = Math.trunc(errorCode / 10);
    }
    if (validationCode === (obfError % 10).toString()) {
      return trueCode;
    }
    return this.defaultEventCode;
  }

  submitForm(form: HTMLFormElement): void {
    form.submit();
  }

  /**
   * @return True if current user try to connect on seco-mobile
   */
  isSecoMobile(): boolean {
    return this.window.location.pathname.startsWith('/mobile');
  }

  /**
   * @return the organisation being used for HELPDESK support mode
   */
  onSupportMode(): string | null {
    return new URLSearchParams(this.window.location.search).get('HELPDESK');
  }
}
