import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertModel } from '../../model/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private readonly alerts$ = new BehaviorSubject<AlertModel[]>([]);

  getAlerts(): Observable<AlertModel[]> {
    return this.alerts$.asObservable();
  }

  addAlert(alert: AlertModel) {
    if (alert.dismissible === undefined) {
      alert.dismissible = true;
    }
    const newAlerts = [...this.alerts$.value, alert];
    if (alert.timer) {
      setTimeout(() => {
        this.closeAlert(alert);
      }, alert.timer);
    }
    this.alerts$.next(newAlerts);
  }

  closeAlert(alert: AlertModel) {
    const index = this.alerts$.value.indexOf(alert);
    if (index !== -1) {
      const newAlerts = [...this.alerts$.value];
      newAlerts.splice(index, 1);
      this.alerts$.next(newAlerts);
    }
  }
}
