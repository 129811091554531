<ng-container *ngIf="(globalFormError$ | async) as formError">
  <ngb-alert
    *ngIf="formError.message && formError.fields"
    dfInsertAlertIcon
    role="alert"
    class="df-page-alert alert-top-0 ps-8"
    type="danger"
    (closed)="closed()"
  >
    <strong
      >{{formError.fields.length}}
      <ng-container i18n="@@newlogin.form.error"
        >{formError.fields.length, plural, =1 {Error} other {Errors}}</ng-container
      ></strong
    >
    - {{formError.message}}
    <ul class="m-0 ps-4">
      <li *ngFor="let field of formError.fields">{{field}}</li>
    </ul>
  </ngb-alert>
</ng-container>
