<div
  class="d-flex flex-nowrap main-overflow h-100 {{(clpConfig$ | async)?.skinName}}-theme"
  [ngClass]="{ 'main-in-app': !router.url.startsWith('/login') }"
  role="main"
>
  <app-global-alert [globalAlert$]="globalAlerts$"></app-global-alert>

  <router-outlet></router-outlet>
</div>

<footer *ngIf="displayFooter" class="footer flex-shrink-0">
  <div
    class="d-flex flex-column-reverse flex-md-row align-items-center h-100 px-sm-6 px-3 py-4 py-md-0 justify-content-md-between"
  >
    <div class="d-flex flex-row justify-content-between">
      <p class="mb-0">
        <span (click)="openSecurityInformationModal()">&copy;&nbsp;</span>
        <a href="https://amadeus.com" target="_blank" rel="noopener noreferrer">Amadeus</a>&nbsp;&nbsp;2022
      </p>
      <button
        i18n="@@newlogin.footer.legal-notices"
        type="button"
        class="legal-notice btn btn-link p-0 ms-9"
        (click)="openLegalNotices()"
        id="legalNotice"
      >
        Legal notices
      </button>
    </div>
    <hr class="d-md-none spacing-04 w-100" />
    <div *ngIf="(clpConfig$ | async)?.skinName === 'topas'" class="travel-information flex-row justify-content-between">
      <p class="m-0" i18n="@@newlogin.footer.travelInformation"
        >토파스여행정보㈜ 대표이사 박종명/ (04532)서울시 중구 소공동 88 한진빌딩 신관 19층/ 사업자등록번호104-81-45671/
        E-mail selpdmt&#64;topas.net</p
      >
    </div>
    <div class="d-flex align-items-center">
      <p
        *ngIf="(clpConfig$ | async)?.skinName === 'topas'"
        class="d-none d-lg-block m-0 me-2"
        i18n="@@newlogin.footer.csCenter"
        >CS-Center 1566-0014</p
      >
      <div ngbDropdown placement="top-right" class="px-4">
        <button class="btn btn-outline-primary" id="select-language" ngbDropdownToggle>
          {{ currentLanguage.languageName }}
        </button>
        <div ngbDropdownMenu aria-labelledby="select-language">
          <ng-container *ngFor="let language of availableLanguages">
            <button
              *ngIf="language.locale !== currentLanguage.locale"
              ngbDropdownItem
              class="dropdown-item"
              (click)="selectLanguage(language.locale)"
            >
              {{ language.languageName }}
            </button>
          </ng-container>
        </div>
      </div>
      <p class="mb-0">
        <button
          *ngIf="(clpConfig$ | async)?.skinName !== 'topas'"
          i18n="@@newlogin.footer.support"
          type="button"
          class="btn btn-link px-2 me-2"
          (click)="goToSupport()"
          id="support"
        >
          Support
        </button>
        <button
          *ngIf="(clpConfig$ | async)?.loginParameters?.contactUrl"
          i18n="@@newlogin.footer.contact"
          type="button"
          class="btn btn-link px-2 me-2"
          (click)="goToContactLink()"
          id="contact"
        >
          Contact us
        </button>
        <button
          i18n="@@newlogin.footer.legal-notices"
          type="button"
          class="btn btn-link px-2 me-2"
          (click)="openLegalNotices()"
          id="legal"
        >
          Legal notices
        </button>
        <button
          *ngIf="(clpConfig$ | async)?.loginParameters?.privacyPolicyUrl"
          i18n="@@newlogin.footer.privacy-policy"
          type="button"
          class="btn btn-link px-2 me-2"
          (click)="goToPrivacyPolicyLink()"
          id="privacyPolicyUrl"
        >
          Privacy Policy
        </button>
      </p>
    </div>
  </div>
</footer>
