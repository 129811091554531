import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { CoreState, selectClpConfig } from '../redux/core.reducer';

@Component({
  selector: 'session-information',
  templateUrl: './session-information.component.html'
})
export class SessionInformationComponent implements OnDestroy {
  public token = '';
  public showIcon = false;
  public label = 'Copy token';
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private readonly store: Store<CoreState>
  ) {
    this.getTokenSession();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public clipboardToken(): void {
    if (this.token) {
      navigator.clipboard.writeText(this.token);
      this.label = 'Copied';
      this.showIcon = true;
    }
  }

  private getTokenSession(): void {
    this.store.select(selectClpConfig).subscribe((config) => {
      if (config?.JsessionId) {
        this.token = config.JsessionId;
      }
    });
  }
}
