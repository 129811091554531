import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap, timeout } from 'rxjs/operators';
import { LoginModule } from '@seco/login';
import { Analytics, AnalyticsService, HttpConfigService, HttpConfig, AnalyticsConfig } from '@seco/core';
import { BearModule, HttpModule as SecoHttpModule } from '@seco/dev-utils';
import { DfAlertModule, DfModalService, DfModule } from '@design-factory/design-factory';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbAlertModule, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CookiePolicyModule } from './cookie-policy/cookie-policy.module';
import { AppComponent } from './app.component';
import { CORE_FEATURE, coreReducer } from './redux/core.reducer';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { LocalesService } from './services/locales/locales.service';
import { AlertService } from './services/alert/alert.service';
import { AuthService } from './services/auth/auth.service';
import { UtilService } from './services/util/util.service';
import { CoreEffect } from './redux/core.effect';
import { GlobalAlertComponent } from './include/global-alert/global-alert.component';
import { FormErrorComponent } from './include/form-error/form-error.component';
import { SessionInformationComponent } from './session-information/session-information.component';
import { SamlSsoModule } from './sso/saml-sso.module';

export function getBaseHref(platformLocation: PlatformLocation, utilService: UtilService): string {
  if (utilService.isSecoMobile()) {
    return '/mobile/home';
  }

  return platformLocation.getBaseHrefFromDOM();
}

export function initializeApp(
  authService: AuthService,
  httpConfigService: HttpConfigService,
  httpClient: HttpClient,
  localeService: LocalesService,
  titleService: Title,
  window: Window
): () => Observable<any> {
  return () => {
    // Fetch site code from url
    const url = new URL(window.location.href);
    const siteCode = url.searchParams.get('SITE') || 'LOGINURL';

    // Language
    const language = localeService.getLanguageCode();

    // base URL
    const baseUrl = '/app_seco_login';

    const initConf: HttpConfig = { siteCode, language, baseUrl };

    // Fetch octx from url
    const octx = url.searchParams.get('OCTX');
    if (octx) {
      initConf.environment = octx;
    }

    httpConfigService.initConfig(initConf);
    // Trigger a timout after 10 secs
    return (
      authService
        .getClpConfig(siteCode)
        .pipe(timeout(AppModule.MAX_INIT_TIME))
        // Set page title
        .pipe(
          map((clpConfig) => {
            const urlLang = url.searchParams.get('LANGUAGE');
            if (!clpConfig?.loginParameters?.detectLang && urlLang && urlLang !== language) {
              const urlLangCode = localeService.getLocaleFromCode(urlLang);
              if (urlLangCode) {
                localeService.updatePreferedLocaleCookie(urlLangCode, true);
              } else {
                window.location.href = url.origin + url.pathname;
              }
            }
            const pageTitleTranslation =
              clpConfig?.skinName === 'topas'
                ? $localize`:@@newlogin.topas.pageTitle:TOPAS Selling Platform Connect (powered by Amadeus)`
                : $localize`:@@newlogin.pageTitle:Amadeus Selling Platform Connect`;
            titleService.setTitle(pageTitleTranslation);
            return clpConfig?.skinName;
          }),
          mergeMap((skinName) => {
            if (skinName !== 'nextgen') {
              return httpClient.get(`theme.${skinName}.css`, { responseType: 'text' }).pipe(
                tap((css) => {
                  const style = window.document.createElement('style');
                  const head = window.document.getElementsByTagName('head')[0];
                  style.appendChild(window.document.createTextNode(css));
                  head.appendChild(style);
                }),
                catchError((err) => {
                  console.log('Error on loading the theme:', err);

                  // Avoid to block application
                  return of();
                })
              );
            }
            return of();
          }),
          catchError((err) => {
            console.log('Error on getClpConfig:', err);

            // Avoid to block application
            return of();
          })
        )
    );
  };
}

const analyticsConfig: AnalyticsConfig = {
  restURI: '/save-frontoffice-data/v1',
  restTimeout: 10000,
  restItemsLimit: 100,
  keyword: 'SECO_ANALYTICS',
  version: '1.0'
};

@NgModule({
  declarations: [AppComponent, LoginComponent, GlobalAlertComponent, FormErrorComponent, SessionInformationComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    StoreModule.forRoot({ [CORE_FEATURE]: coreReducer }),
    SecoHttpModule.forRoot(),
    EffectsModule.forRoot([CoreEffect]),
    LoginModule,
    SamlSsoModule,
    CookiePolicyModule,
    DfAlertModule,
    NgbAlertModule,
    NgbDropdownModule,
    NgSelectModule,
    NgbModalModule,
    FormsModule,
    BearModule,
    AppRoutingModule,
    DfModule
  ],
  providers: [
    AlertService,
    AuthService,
    UtilService,
    DfModalService,
    { provide: 'Window', useValue: window },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation, UtilService]
    },
    Analytics,
    { provide: 'analyticsConfig', useValue: analyticsConfig },
    { provide: AnalyticsService, useExisting: Analytics },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, HttpConfigService, HttpClient, LocalesService, Title, 'Window'],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Max time to startup app : 10 secs
  public static readonly MAX_INIT_TIME = 10_000;
}
