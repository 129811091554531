import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SamlSsoModule } from './sso/saml-sso.module';

export const routes: Routes = [
  { path: 'loginFromSAMLSSO', loadChildren: () => SamlSsoModule },
  { path: 'redirectSAMLSSO', loadChildren: () => SamlSsoModule },
  { path: '', component: LoginComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
