import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const DEFAULT_LOCALE = 'en-US';

export type SupportedLocale =
  | 'de'
  | 'en-GB'
  | 'en-US'
  | 'es'
  | 'zh-Hant'
  | 'zh'
  | 'fr'
  | 'ko'
  | 'it'
  | 'ja'
  | 'br'
  | 'ru';
export interface LocaleDefinition {
  languageCode: string;
  languageName: string;
  languageCLP: string;
}
@Injectable({
  providedIn: 'root'
})
export class LocalesService {
  public readonly supportedLocales: Record<SupportedLocale, LocaleDefinition> = {
    de: {
      languageCode: 'DE',
      languageName: 'Deutsch',
      languageCLP: 'de_de'
    },
    'en-GB': {
      languageCode: 'GB',
      languageName: 'English (UK)',
      languageCLP: 'en_gb'
    },
    [DEFAULT_LOCALE]: {
      languageCode: 'US',
      languageName: 'English (US)',
      languageCLP: 'en_us'
    },
    es: {
      languageCode: 'ES',
      languageName: 'Español',
      languageCLP: 'es_es'
    },
    'zh-Hant': {
      languageCode: 'TW',
      languageName: '繁體中文',
      languageCLP: 'zh_tw'
    },
    zh: {
      languageCode: 'CN',
      languageName: '简体中文',
      languageCLP: 'zh_cn'
    },
    fr: {
      languageCode: 'FR',
      languageName: 'Français',
      languageCLP: 'fr_fr'
    },
    ko: {
      languageCode: 'KO',
      languageName: '한국어',
      languageCLP: 'ko_kr'
    },
    it: {
      languageCode: 'IT',
      languageName: 'Italiano',
      languageCLP: 'it_it'
    },
    ja: {
      languageCode: 'JP',
      languageName: '日本語',
      languageCLP: 'ja_jp'
    },
    br: {
      languageCode: 'BR',
      languageName: 'Português (BR)',
      languageCLP: 'pt_br'
    },
    ru: {
      languageCode: 'RU',
      languageName: 'Русский',
      languageCLP: 'ru_ru'
    }
  };

  constructor(
    @Inject(LOCALE_ID) private readonly locale: SupportedLocale,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  getLanguageCode(): string {
    const supportedLocale = this.supportedLocales[this.locale];
    if (supportedLocale) {
      return supportedLocale.languageCode;
    }
    return this.supportedLocales[DEFAULT_LOCALE].languageCode;
  }

  getLanguageCLPCode(): string {
    const supportedLocale = this.supportedLocales[this.locale];
    if (supportedLocale) {
      return supportedLocale.languageCLP;
    }
    return this.supportedLocales[DEFAULT_LOCALE].languageCLP;
  }

  getAvailableLanguages(): { languageName: string; locale: SupportedLocale }[] {
    return (Object.keys(this.supportedLocales) as SupportedLocale[]).map((locale: SupportedLocale) => ({
      languageName: this.supportedLocales[locale].languageName,
      locale
    }));
  }

  getDefaultLocaleUsed(): SupportedLocale {
    const supportedLocale = this.supportedLocales[this.locale];
    if (supportedLocale) {
      return this.locale;
    }
    return DEFAULT_LOCALE;
  }

  getLocaleFromCode(languageCode: string): SupportedLocale {
    return (Object.keys(this.supportedLocales) as SupportedLocale[]).find(
      (locale) => this.supportedLocales[locale].languageCode === languageCode
    ) as SupportedLocale;
  }

  /**
   * Update prefered locale cookie to use this locale for the next app load.
   */
  updatePreferedLocaleCookie(selectedLocale: SupportedLocale, shouldRefresh: boolean) {
    const expirationDate = new Date();
    // Expiration time of the cookie is set to 30 days.
    expirationDate.setTime(expirationDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    this.document.cookie = `loginPreferedLocale=${selectedLocale}; expires=${expirationDate.toUTCString()}; path=/; secure; domain=${
      this.document.location.hostname
    }`;
    if (shouldRefresh) {
      const url = new URL(this.document.location.href);
      if (url.searchParams.get('LANGUAGE')) {
        url.searchParams.set('LANGUAGE', this.supportedLocales[selectedLocale]?.languageCode);
      }
      this.reload(url.href);
    }
  }

  /**
   * Reload page with given url
   */
  reload(url: string): void {
    this.document.location.replace(url);
  }

  getLanguageName(): string {
    const supportedLocale = this.supportedLocales[this.locale];
    if (supportedLocale) {
      return supportedLocale.languageName;
    }
    return this.supportedLocales[DEFAULT_LOCALE].languageName;
  }
}
