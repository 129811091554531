import { Inject, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { LocalesService } from '../services/locales/locales.service';
import { CoreState, selectClpConfig } from '../redux/core.reducer';
import { ClpInitConfigExtended } from '../model/clpInitConfigExtended.model';
@Component({
  templateUrl: './redirect-saml-sso.component.html'
})
export class RedirectSamlSsoComponent {
  clpConfig$: Observable<ClpInitConfigExtended | undefined>;
  isSSOEnable = true;

  constructor(
    private readonly store: Store<CoreState>,
    private readonly localesService: LocalesService,
    @Inject('Window') private readonly window: Window
  ) {
    this.clpConfig$ = this.store.select(selectClpConfig);
    this.clpConfig$.subscribe((config) => {
      if (config?.nonce) {
        this.isSSOEnable = config.loginParameters.externalSsoEnabled;
        if (this.isSSOEnable) {
          this.processSsoClpConfig(config);
        }
      }
    });
  }

  processSsoClpConfig(response: ClpInitConfigExtended): void {
    const currentUrl = new URL(this.window.location.href);
    const siteCode = currentUrl.searchParams.get('SITE') || 'LOGINURL';
    const language = currentUrl.searchParams.get('LANGUAGE') || this.localesService.getLanguageCode();
    const isClientIdIncluded = currentUrl.searchParams.get('INCLUDE_CLIENT_ID');
    const providerId = currentUrl.searchParams.get('PROVIDER_ID');
    const octx = currentUrl.searchParams.get('OCTX');

    let clpRelativeUrl = response.loginParameters.ssoClpPathUrl;
    if (isClientIdIncluded?.toUpperCase() !== 'TRUE') {
      clpRelativeUrl = clpRelativeUrl.replace('&client_id=SECO_%PROVIDER_ID%', '');
    }

    if (providerId) {
      clpRelativeUrl = clpRelativeUrl.replace(/%PROVIDER_ID%/g, providerId);
    }

    let redirectURI = `https://${currentUrl.hostname}/login/loginFromSAMLSSO?SITE=${siteCode}&LANGUAGE=${language}&nonce=${response.nonce}`;
    redirectURI = octx ? `${redirectURI}&OCTX=${octx}` : redirectURI;
    redirectURI = encodeURIComponent(redirectURI);
    clpRelativeUrl = clpRelativeUrl.replace('%REDIRECT_URI%', redirectURI);
    this.window.location.href = `${response.baseUrl}${clpRelativeUrl}&response_mode=query&nonce=${response.nonce}`;
  }

  goToHost(): void {
    this.window.location.href = `${this.window.location.protocol}//${this.window.location.hostname}`;
  }
}
