import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CookiePolicyComponent } from './cookie-policy.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [CookiePolicyComponent],
  imports: [CommonModule, NgbModalModule, HttpClientModule],
  exports: [CookiePolicyComponent],
  providers: [NgbActiveModal]
})
export class CookiePolicyModule {}
