<div class="h-100">
  <div class="login-alert-content col-lg-12 position-fixed px-0 py-0">
    <form-error-alert [globalFormError$]="globalFormError$"></form-error-alert>
  </div>
  <div class="container-fluid h-100">
    <div class="row h-100 login-display">
      <div
        id="main-background"
        class="col-12 col-lg-7 login-content px-0"
        [ngClass]="{'login-background': (clpConfig$ | async)?.skinName === 'nextgen'}"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-8 col-xxl-7 px-8">
              <h1 class="py-5 py-sm-10 m-0 d-block d-lg-none">
                <div class="title-sm" [ngClass]="{'title-sm-amadeus': (clpConfig$ | async)?.skinName === 'nextgen'}">
                </div>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5 px-0 py-8 login-flow">
        <div
          id="main-logo"
          class="ms-auto me-5 me-sm-9"
          [ngClass]="{'df-amadeuslogo-primary': (clpConfig$ | async)?.skinName === 'nextgen'}"
        ></div>
        <div class="container-fluid h-100 d-flex flex-column">
          <div class="row justify-content-center align-items-center flex-grow-1">
            <div class="col-12 col-sm-10 ms-8 me-8 mt-6">
              <ng-container *ngIf="impersonate && (titleInformation$ | async)?.titleState === 'login' ">
                <ngb-alert dfInsertAlertIcon type="warning" [dismissible]="false">
                  <span class="alert-icon" aria-hidden="true"></span>
                  <ng-container i18n="@@newlogin.onJumpLoginMode.warning"
                    >This site is reserved to Support users only</ng-container
                  >
                </ngb-alert>
              </ng-container>
              <ng-container *ngIf="(titleInformation$ | async)?.titleState === 'jumpLogin'">
                <ngb-alert dfInsertAlertIcon type="warning" [dismissible]="false">
                  <span class="alert-icon" aria-hidden="true"></span>
                  <ng-container i18n="@@newlogin.jumpLogin.warning"
                    >You are personally responsible for all action performed during this impersonation
                    session.</ng-container
                  >
                  <hr />
                  <span i18n="@@newlogin.jumpLogin.warning.explanation"
                    >Actions performed during the impersonation session are done live on the production site. Please
                    ensure that you do not perform any actions in this impersonation session that may incur financial
                    consequences or impact in the production site.</span
                  >
                </ngb-alert>
              </ng-container>
              <ng-container
                *ngIf="!(clpConfig$ | async)?.loginParameters?.mfaWarningActivated
                && (clpConfig$ | async)?.loginParameters?.phishingAlertEnabled && (clpConfig$ | async)?.skinName !== 'topas'"
              >
                <ngb-alert class="custom-alert" type="danger" [dismissible]="true">
                  <span class="df-insert-icon fa-exclamation-triangle alert-icon" aria-hidden="true"></span>
                  <strong class="m-0" i18n="@@newlogin.login.phishing.alert.title"
                    >Phishing Alert - Selling Platform Connect
                  </strong>
                  <hr class="mt-3 mb-3" />
                  <p class="m-0" i18n="@@newlogin.login.phishing.alert.description">
                    How to stay protected?
                    <a href="javascript:void(0)" (click)="openPhishingAlert()">
                      Read this article.
                      <span role="img" class="fa-external-link" aria-hidden="true"></span>
                    </a>
                  </p>
                </ngb-alert>
              </ng-container>
              <ng-container *ngIf="(clpConfig$ | async)?.loginParameters?.mfaWarningActivated">
                <ngb-alert class="custom-alert" type="info" [dismissible]="true">
                  <span class="df-insert-icon fa-circle-info alert-icon" aria-hidden="true"></span>
                  <p class="m-0" i18n="@@newlogin.login.mfa.warning.message">
                    A more secure sign-in process is coming soon ! <br />
                    <a href="javascript:void(0)" (click)="openMfaWarning()">
                      Find out more.
                      <span role="img" class="fa-external-link" aria-hidden="true"></span>
                    </a>
                  </p>
                </ngb-alert>
              </ng-container>
            </div>
            <div class="col-12 col-sm-8">
              @if ((clpConfig$ | async)?.loginParameters?.mfaSelfRegistrationActivated && (loginInformation$ |
              async)?.username && (loginInformation$ | async)?.selectedMFA) {
              <div class="username">{{(loginInformation$ | async)?.username}}</div>
              }
              <h2 class="h1">{{ (titleInformation$ | async)?.title }}</h2>
              <p class="mb-7 h4">{{ (titleInformation$ | async)?.subtitle }}</p>
              <ama-ng-login></ama-ng-login>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
