export interface TitleInformation {
  title: string;
  subtitle: string;
  titleState: TitleState;
}

export enum TitleState {
  login = 'login',
  forgetPwd = 'forgetPwd',
  validatePwd = 'validatePwd',
  expiredPwd = 'expiredPwd',
  ddna = 'ddna',
  jumpLogin = 'jumpLogin'
}
