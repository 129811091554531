import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LocalesService } from '../services/locales/locales.service';

@Component({
  selector: 'login-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['cookie-policy.style.scss']
})
export class CookiePolicyComponent {
  private static readonly urlPrefix = 'assets/legal/legalDocument_';
  private static readonly urlExt = '.html';
  public content: SafeHtml | undefined;
  private static readonly contentCache: Map<string, SafeHtml> = new Map<string, SafeHtml>();

  constructor(
    public activeModal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly localesService: LocalesService,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.loadAndShowPolicies();
  }

  public scrollTo(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  }

  public loadAndShowPolicies() {
    const url = `${CookiePolicyComponent.urlPrefix}${this.localesService.getLanguageCode()}${
      CookiePolicyComponent.urlExt
    }`;
    this.http.get(url, { responseType: 'text' }).subscribe(
      (response) => {
        CookiePolicyComponent.contentCache.set(
          this.localesService.getLanguageCode(),
          this.domSanitizer.bypassSecurityTrustHtml(response)
        );
        this.content = CookiePolicyComponent.contentCache.get(this.localesService.getLanguageCode());
      },
      (error) => {
        throw new Error(`Error:${error}:`);
      }
    );
  }
}
