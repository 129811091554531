<ng-container *ngIf="!isSSOEnable">
  <h1 id="error_title" i18n="@@newlogin.sso.error-title">Direct access denied:</h1>
  <p id="error_message" i18n="@@newlogin.sso.error-message">
    Redirection to Selling Platform Connect is not possible. Please contact your administrator.
  </p>
  <p id="link_message" i18n="@@newlogin.sso.link-message">
    If you wish to be redirected to the Login Page of Selling Platform Connect, please use the following
    <a title="Selling Platform Connect" href="javascript:void(0)" (click)="goToHost()">link</a>.
  </p>
</ng-container>
