import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Analytics, HttpService, UserConfig } from '@seco/core';
import { ClpLoginResponse, getLoginInformation } from '@seco/login';
import { Observable } from 'rxjs';
import { CoreState } from '../../redux/core.reducer';
import { UtilService } from '../util/util.service';
import { updateClpConfig } from '../../redux/core.actions';
import { map } from 'rxjs/operators';
import { LocalesService } from '../locales/locales.service';
import { ClpInitConfigExtended } from '../../model/clpInitConfigExtended.model';
import { LoginInformation } from '@seco/login/src/app/login-information.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly BUILD_CONFIG_TOKEN = 'buildConfigToken';
  private readonly SIGN_OUT_ACTION = 'logout';
  private readonly REDIRECT_BOOKING_FARM = 'redirectBookingFarm';
  private readonly BOOKING_MOBILE_PATH = '/app_mobile/apfplus/modules/mobile/home?';
  private currentLoginInformation: LoginInformation = {};

  constructor(
    private readonly store: Store<CoreState>,
    private readonly httpService: HttpService,
    private readonly utilService: UtilService,
    private readonly analytics: Analytics,
    private readonly localeService: LocalesService
  ) {
    this.store?.select(getLoginInformation).subscribe((loginInformation) => {
      this.currentLoginInformation = loginInformation;
    });
  }

  /**
   * Get token and nonce by calling UM action.
   */
  getClpConfig(site: string): Observable<ClpInitConfigExtended | undefined> {
    const isMobile = this.utilService.isSecoMobile();
    const onSupportMode = this.utilService.onSupportMode();
    const baseUri = window.location.href;
    return this.httpService.postApfPlus('newlogin', this.BUILD_CONFIG_TOKEN, { isMobile, onSupportMode, baseUri }).pipe(
      map((res: any) => {
        const response = res.response.model;

        if (response.loginParameters?.redirectURL) {
          const url = new URL(response.loginParameters.redirectURL);
          const currentUrl = new URL(window.location.href);

          // Merge the query parameters
          const mergedParams = [...currentUrl.searchParams.entries(), ...url.searchParams.entries()];

          const params = new URLSearchParams();

          mergedParams.forEach((param) => {
            // set method will add a key/value couple and remove any previous occurrence of the same couple, so we avoid duplicated params
            params.set(param[0], param[1]);
          });

          params.delete('SITE');

          url.search = params.toString();
          location.assign(url.toString());
        } else {
          if (this.utilService.isSecoMobile()) {
            response.loginParameters.displayDutyCode = false; // Duty code doesn't exist for seco-mobile
          }
          const clpInitConfigExtended = {
            token: response.token,
            nonce: response.nonce,
            baseUrl: response.baseUrl,
            skinName: response.skinName,
            loginParameters: response.loginParameters,
            JsessionId: res.response?.JsessionId
          };
          this.store.dispatch(updateClpConfig(clpInitConfigExtended));

          const userData: UserConfig = {
            language: this.localeService.getLanguageCode(),
            site,
            jSessionId: res.response?.JsessionId
          };
          this.analytics.setUserData(userData);

          return clpInitConfigExtended;
        }
      })
    );
  }

  redirectBookingFarm(clpLoginResponse: ClpLoginResponse, queryParams: { [key: string]: string }) {
    this.httpService.postApfPlus('newlogin', this.REDIRECT_BOOKING_FARM, clpLoginResponse).subscribe((res: any) => {
      const response = res.response.model;

      const urlParams = Object.keys(queryParams)
        .filter(
          (paramKey: string) =>
            ['LANGUAGE', 'SITE', 'e', 'event', 'appUri', 'ACTION', 'OCTX', 'eventCode', 'err', 'OFC'].indexOf(
              paramKey
            ) === -1
        )
        // add context for logout flow
        // filter these params because they are added by the redirection, and we don't want to have them duplicate
        .map((paramKey: string) => `${paramKey}=${queryParams[paramKey]}`);

      let bfUrl;
      const isSecoMobile = this.utilService.isSecoMobile();
      if (isSecoMobile) {
        // Split url and parameters
        const match = response.url.match('(https:.*)/app_sell2.0/.*\\?(.*)$');
        const url = match[1];
        const parameters = match[2];

        // Build url
        bfUrl = url + this.BOOKING_MOBILE_PATH + parameters;
      } else {
        // Adding office in query params for post logins ads - to be removed after bootstrap rework
        if (this.currentLoginInformation?.officeId) {
          urlParams.push('OFC=' + this.currentLoginInformation.officeId.toUpperCase());
        }

        bfUrl = `${response.url.replace(/&nonce=[A-Za-z0-9]*/g, '')}`;
      }

      if (urlParams.length > 0) {
        // add & for another parameter
        bfUrl += '&' + urlParams.join('&');
      }
      this.utilService.createForm(bfUrl, clpLoginResponse);
    });
  }

  logOut(callback: () => void) {
    this.httpService.postApfPlus('newlogin', this.SIGN_OUT_ACTION, {}).subscribe(() => {
      callback();
    });
  }
}
