<div>
  <div class="modal-header pb-0">
    <h2 class="modal-title" i18n="@@newlogin.security.information.title">Login Page Security Token</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <input type="text" class="form-control" aria-label="Token input" readonly value="{{token}}" />
      <button
        *ngIf="token"
        type="button"
        class="btn btn-primary w-25"
        (click)="clipboardToken()"
        aria-label="Copy"
        i18n-aria-label="@@newlogin.security.information.button.copyAria"
      >
        <span *ngIf="showIcon" class="icon-check" aria-hidden="true"></span>
        <ng-container i18n="@@newlogin.security.information.button.copy">{{ label }}</ng-container>
      </button>
    </div>
  </div>
</div>
