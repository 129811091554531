<ng-container *ngFor="let alert of globalAlert$ | async">
  <ngb-alert
    dfInsertAlertIcon
    class="df-page-alert alert-top-0 ps-8"
    [type]="alert.type"
    [ngSwitch]="alert.state"
    [dismissible]="!!alert.dismissible"
    (closed)="alertService.closeAlert(alert)"
  >
    <ng-container *ngSwitchCase="State.OTP">
      <!-- Use innerHTML to convert html tags (<strong>) inside i18n $localize (from @seco/login) -->
      <span [innerHTML]="alert.data"></span>
    </ng-container>

    <ng-container *ngSwitchCase="State.MESSAGE">
      <span [innerHTML]="alert.message"></span>
    </ng-container>

    <ng-container *ngSwitchCase="State.FIRST_VISIT">
      <div class="d-flex flex-row">
        <div class="me-5">
          <span [innerHTML]="alert.message"></span>
          <a class="link-normal" href="javascript:void(0);" (click)="openCookiePopUp()" i18n="@@newlogin.cookies.link">
            Cookie policy.
          </a>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            aria-label="Accept"
            i18n="@@newlogin.cookies.accept"
            (click)="acceptPolicies(alert)"
            >Accept</button
          >
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>{{ alert.data }}</ng-container>
  </ngb-alert>
</ng-container>
