import { Component, Input } from '@angular/core';
import { DfModalService } from '@design-factory/design-factory';
import { Observable } from 'rxjs';
import { CookiePolicyComponent } from '../../cookie-policy/cookie-policy.component';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { AlertService } from '../../services/alert/alert.service';
import { AlertModel, Flow } from '../../model/alert.model';

const cookiePolicy = '11/06/2024';

@Component({
  selector: 'app-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss']
})
export class GlobalAlertComponent {
  @Input() globalAlert$!: Observable<AlertModel[]>;
  State = Flow;

  constructor(
    readonly alertService: AlertService,
    private readonly modalService: DfModalService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public openCookiePopUp(): void {
    this.modalService.open(CookiePolicyComponent, { centered: true, size: 'lg' });
  }

  public acceptPolicies(alert: AlertModel): void {
    this.alertService.closeAlert(alert);
    this.localStorageService.storeItem(cookiePolicy);
  }
}
