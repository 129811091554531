import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RedirectSamlSsoComponent } from './redirect-saml-sso.component';
import { LoginFromSamlSsoComponent } from './login-from-saml-sso.component';

export const routes: Routes = [
  { path: 'loginFromSAMLSSO', component: LoginFromSamlSsoComponent },
  { path: 'redirectSAMLSSO', component: RedirectSamlSsoComponent }
];

@NgModule({
  declarations: [RedirectSamlSsoComponent, LoginFromSamlSsoComponent],
  imports: [RouterModule.forChild(routes), CommonModule, HttpClientModule],
  exports: [RedirectSamlSsoComponent, LoginFromSamlSsoComponent]
})
export class SamlSsoModule {}
