import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormAlert } from '../../model/form-alert';
import { clearFormAlert } from '../../redux/core.actions';
import { CoreState } from '../../redux/core.reducer';

@Component({
  selector: 'form-error-alert',
  templateUrl: './form-error.component.html',
  styleUrls: ['form-error.component.scss']
})
export class FormErrorComponent {
  @Input() globalFormError$!: Observable<FormAlert | undefined>;

  constructor(private readonly store: Store<CoreState>) {}

  closed() {
    this.store.dispatch(clearFormAlert());
  }
}
