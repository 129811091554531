import { Inject, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ClpLoginResponse } from '@seco/login';
import { CoreState, selectClpConfig } from '../redux/core.reducer';
import { HttpService } from '@seco/core';
import { UtilService } from '../services/util/util.service';
import { ClpInitConfigExtended } from '../model/clpInitConfigExtended.model';
@Component({
  templateUrl: './login-from-saml-sso.component.html'
})
export class LoginFromSamlSsoComponent {
  clpConfig$: Observable<ClpInitConfigExtended | undefined>;
  isSSOEnable = true;

  constructor(
    private readonly httpService: HttpService,
    private readonly store: Store<CoreState>,
    private readonly utilService: UtilService,
    @Inject('Window') private readonly window: Window
  ) {
    this.clpConfig$ = this.store.select(selectClpConfig);
    this.clpConfig$.subscribe((config) => {
      if (config?.nonce) {
        this.isSSOEnable = config.loginParameters.externalSsoEnabled;
        if (this.isSSOEnable) {
          this.resolveFarmLink();
        }
      }
    });
  }

  resolveFarmLink(): void {
    const currentUrl = new URL(this.window.location.href);
    const accessToken = currentUrl.searchParams.get('access_token') || '';
    const idToken = currentUrl.searchParams.get('id_token') || '';
    const nonce = currentUrl.searchParams.get('nonce') || '';
    const parameters: ClpLoginResponse = {
      accessToken,
      idToken,
      nonce
    };

    this.httpService.postApfPlus('newlogin', 'redirectBookingFarm', parameters).subscribe((res: any) => {
      const response = res.response.model;
      this.processResponseResolveFarmLink(response, parameters);
    });
  }

  processResponseResolveFarmLink(response: any, parameters: ClpLoginResponse): void {
    // Delete nonce in the Url
    const bfUrl = response?.url?.replace(/&nonce=[A-Za-z0-9]*/g, '');
    // Redirect to Booking Farm
    this.utilService.createForm(bfUrl, parameters, true);
  }

  goToHost(): void {
    this.window.location.href = `${this.window.location.protocol}//${this.window.location.hostname}`;
  }
}
